import React, { useEffect, useState } from 'react'
import { plazaServices } from '../tollPlaza/plazaReportService';
import HighchartsReact from "highcharts-react-official";
import { useHistory } from 'react-router-dom';
import Highcharts from "highcharts";

export const Dashboard = () => {
    const [data, setData] = useState([])
    const [orderbyStatus, setOrderByStatus] = useState([])
    const [line, setLine] = useState(null);
    const [masterData, setMasterData] = useState([])
    const history = useHistory();
    useEffect(() => {
        const payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $group: {
                        _id: "$walmart.listingStatus",
                        count: { $sum: 1 },

                    }
                },
                {
                    $project: {
                        _id: 0,
                        status: "$_id",
                        count: 1
                    }
                },
            ]
        }
        const orderStatusPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $group: {
                        _id: "$lastStatus",
                        count: { $sum: 1 },
                        total: { $sum: `$charge.amount` },
                        totalType: { $sum: `$qty` }

                    }
                },
                {
                    $project: {
                        _id: 0,
                        status: "$_id",
                        count: 1,
                        total: 1,
                        totalType: 1
                    }
                },
            ]
        }

        const lineChartPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        deliveryDate: { $exists: true }
                    }
                },
                {
                    $group: {
                        _id: "$sku",
                        deliveryDate: { "$first": "$indiaShipment.delivery" },
                        expectedDeliveryDate: { "$first": "$indiaShipment.expectedDelivery" }

                    }
                },
                {
                    $project: {
                        _id: 0,
                        sku: "$_id",
                        deliveryDate: 1,
                        expectedDeliveryDate: 1
                    }
                },
            ]

        }

        Promise.all([plazaServices.general(serviceId, payload, "nq-processing-status"), plazaServices.general(serviceId, orderStatusPayload, "nq-order-items"), plazaServices.general(serviceId, lineChartPayload, "nq-order-items")]).then((res) => {
            let data = pieChartType(res[0], "Status Wise Isbn Listing Breakup", "/walmart-tittle")
            setData(data);
            data = pieChartType(res[1], "Status Wise Order Breakup", "/wm-orders")
            setOrderByStatus(data);
            let statusMap = {
                "ShippedToUS": { count: 0, total: 0, status: "ShippedToUS" },
                "Shipped": { count: 0, total: 0, status: "Shipped" },
                "Delivered": { count: 0, total: 0, status: "Delivered" },
                "SentToRepro": { count: 0, total: 0, status: "SentToRepro" },
                "Cancelled": { count: 0, total: 0, status: "Cancelled" },
                "Acknowledged": { count: 0, total: 0, status: "Acknowledged" },
                "inTransit": { count: 0, total: 0, status: "InTransit" },
                "inProcess": { count: 0, total: 0, status: "In Process" },
                "total": { count: 0, total: 0, status: "Total Order" }
            };
            let totalcount = 0;
            let totalTotal = 0;
            res[1].forEach((item) => {
                if (statusMap[item.status]) {
                    statusMap[item.status] = item;
                }
                totalTotal += item.total || 0;
                totalcount += item.count || 0;
            });
            statusMap["inProcess"].count = totalcount - (statusMap["Delivered"].count || 0) - (statusMap["Cancelled"].count || 0);
            statusMap["inProcess"].total = totalTotal - (statusMap["Delivered"].total || 0) - (statusMap["Cancelled"].total || 0);
            statusMap["inTransit"].count = statusMap["inProcess"].count - (statusMap["Acknowledged"].count || 0);
            statusMap["inTransit"].total = statusMap["inProcess"].total - (statusMap["Acknowledged"].total || 0);
            statusMap["total"].total = totalTotal;
            statusMap["total"].count = totalcount
            statusMap["Ack"] = statusMap["Acknowledged"]
            statusMap["Ack"].status = "Ack";
            setMasterData(statusMap);
            if (res[2].length) {
                let lateData = [];
                let sku = [];
                res[2].map((item) => {
                    const date1 = new Date(item.deliveryDate);
                    const date2 = new Date(item.expectedDeliveryDate);
                    const differenceInMilliseconds = date2 - date1;
                    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
                    sku.push(item.sku);
                    lateData.push(differenceInDays)
                })
                const lineChartConfig = LineChart(sku, lateData);
                setLine(lineChartConfig);
            }
        })
    }, [])
    const serviceId = localStorage.getItem("serviceId");
    const showCharts = (charts, col, card) => {
        return <div className='col-12 h-auto'>
            <div className='col-12'><HighchartsReact highcharts={Highcharts} options={charts} /></div>
        </div>
    }

    const pieChartType = (res, field, route) => {
        let siteCount = [];
        let checkData = 0;
        res.map((item) => {
            if (item.count === null) item.count = 0
            let data = {
                category: item.status,
                name: item.status,
                y: item.count
            }
            if (item.count > 0) checkData = 1;
            if(item.status.length)siteCount.push(data);
        })
        if (!checkData) siteCount = [];
        let slabs = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
            },
            title: {
                text: `${field}`,
                style: {
                    fontSize: "21px",
                    fontWeight: "700",
                    fontFamily: "Roboto"
                },
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.y}</b>",
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b>: {point.y}",
                        style: {
                            fontSize: "11px",
                        },
                    },
                    point: {
                        events: {
                            click: function (e) {
                                history.push(`${route}`, { message: e.point.category });
                            }
                        }
                    },
                    showInLegend: false,
                },
            },
            series: [
                {
                    name: "",
                    colorByPoint: true,
                    data: siteCount
                },
            ],
        };
        return slabs;
    }

    const LineChart = (names, count) => {
        let seriesData = [];
        seriesData.push({
            name: "",
            data: count
        })

        const lineChart = {
            chart: {
                type: 'spline'
            },
            title: {
                text: 'Order SLA Violation',
                align: 'left'
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            xAxis: {
                categories: names,
                accessibility: {
                    rangeDescription: ''
                }
            },
            yAxis: {
                title: {
                    text: 'Number of Days'
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },

                }
            },
            series: seriesData,
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: ['viewFullscreen', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
                    }
                }
            }
        };

        return lineChart;
    };

    const showMessage = (Message) => {
        return <div className="my-info-message borderStyle2 " style={{ borderRadius: "4px" }}>{Message}</div>;
    }

    const yesterdayCard = (borderStyle, mt, arr, headColour, pad, header, width) => {
        return (
            <div className={`my-class shadow-3 ${borderStyle} col-12 m-0 p-3 grid justify-content-center align-items-center`} style={{ minHeight: '200px' }}>
                <div className='text-center'>
                    <h1 className='text-xl ' style={{
                        overflowWrap: "break-word",
                        wordBreak: "break-word",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal"
                    }}>{header.length ? header : ""}</h1>
                </div>
                <div className={`grid justify-content-between align-items-center mt-0 card ${borderStyle} col-12`} style={{ padding: pad }}>
                    {arr && arr.length ? arr.map((item, ind) => (
                        <div className={`flex flex-column align-items-center justify-content-center  p-2 col-12 ${width}`} key={item ? item.status : ind}>
                            <div className='col-12 text-left text-l mb-0'>
                                <p className={`${headColour} font-bold`} style={{ fontSize: "1.2rem" }}>{item && item.status}</p>
                            </div>
                            <div className='col-12 text-l font-bold mb-2'>
                                Qty: <span className='text-xl'>{item && item.count}</span>
                            </div>
                            <div className='col-12 text-l font-bold mb-2'>
                                Value: <span className='text-xl'>${item && item.total.toFixed(2)}</span>
                            </div>
                        </div>
                    )) : (
                        <div className='col-12 text-center'>No items available</div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className='grid col-12 justify-content-center ml-1 m-0'>
            <div className='grid nested-grid col-12 m-0'>
                <div className=' card col-12 lg:col-6 flex  justify-content-center mt-1 align-items-center col-12   borderStyle2 mt-3 '>
                    {showCharts(orderbyStatus, "", "")}
                </div>
                <div className=' col-12 lg:col-6 flex flex-wrap'>
                    <div className='flex  col-12  lg:col-12   m-0  pb-2 '>{yesterdayCard("borderStyle1", "mt-1", [masterData["total"], masterData["Cancelled"], masterData["inProcess"],masterData["Delivered"]], "text-primary", "pb-0", "Order Status Breakup", "lg:col-3")}</div>
                    <div className='flex  col-12  lg:col-12    m-0 '>{yesterdayCard("borderStyle1", "mt-1", [masterData["Ack"], masterData["SentToRepro"], masterData["inTransit"]], "text-primary", "pb-0", "In Process Orders Breakup", "lg:col-2")}</div>
                </div>
            </div>
            <div className='grid col-12 gap-1 justify-content-between lg:flex-wrap'>
                <div className='col-12 card borderStyle2 lg:col-6 flex justify-content-center'>
                    {showCharts(data, "", "")}
                </div>
                <div className='col-12  lg:col-6 '>{line ? showCharts(line) : <>
                    {showMessage("No Orders are Delivered")}
                </>}
                </div>
            </div>
        </div>
    )
}
